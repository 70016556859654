/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./shared/directives/component-host.directive";
import * as i4 from "./app-loader/app-loader.component.ngfactory";
import * as i5 from "./app-loader/app-loader.component";
import * as i6 from "./app.component";
import * as i7 from "./shared/services/app-overlays.service";
import * as i8 from "./shared/services/app-layout.service";
import * as i9 from "./shared/services/window-events.service";
import * as i10 from "./shared/services/auth.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { overlayHost: 0 }), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(2, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(16777216, [["overlayHost", 2]], null, 1, null, View_AppComponent_1)), i1.ɵdid(4, 16384, [[1, 4]], 0, i3.ComponentHostDirective, [i1.ViewContainerRef], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "ra-app-loader", [], [[8, "hidden", 0]], null, null, i4.View_AppLoaderComponent_0, i4.RenderType_AppLoaderComponent)), i1.ɵdid(6, 114688, null, 0, i5.AppLoaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 6, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isLoading; _ck(_v, 5, 0, currVal_0); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-app", [], [[1, "app-version", 0], [2, "is-mobile", null]], [["document", "touchstart"], ["document", "mousemove"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("document:touchstart" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onTouchStart() !== false);
        ad = (pd_0 && ad);
    } if (("document:mousemove" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onMouseMove() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onMouseClick() !== false);
        ad = (pd_2 && ad);
    } return ad; }, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 4308992, null, 0, i6.AppComponent, [i7.AppOverlaysService, i8.AppLayoutService, i9.WindowEventsService, i10.AuthService, i2.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).appVersion; var currVal_1 = i1.ɵnov(_v, 1).isMobile; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var AppComponentNgFactory = i1.ɵccf("ra-app", i6.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
