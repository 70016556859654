/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alert.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "helio-games-core";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "./alert.component";
import * as i7 from "../shared/services/app-overlays.service";
import * as i8 from "../app-header/app-lang-select/app-lang.service";
var styles_AlertComponent = [i0.styles];
var RenderType_AlertComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertComponent, data: { "animation": [{ type: 7, name: "fade", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: "250ms" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 6, styles: { opacity: 1 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: "250ms" }], options: null }], options: {} }] } });
export { RenderType_AlertComponent as RenderType_AlertComponent };
function View_AlertComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpod(2, { "errorCode": 0 }), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵppd(6, 4), i1.ɵpod(7, { "time": 0 }), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 3).transform("general.alerts.error-code", _ck(_v, 2, 0, _co.errorCode))); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 8).transform("general.alerts.timestamp", _ck(_v, 7, 0, i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent, 1), _co.timestamp, "short", "+0000", _co.localeID))))); _ck(_v, 5, 0, currVal_1); }); }
function View_AlertComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "default-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirmAlert() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.confirmBtnText)); _ck(_v, 1, 0, currVal_0); }); }
export function View_AlertComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.ɵcp, [i4.DomSanitizer]), i1.ɵpid(0, i5.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(2, 0, null, null, 13, "div", [["class", "alert"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "p", [["class", "content"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(7, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertComponent_1)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertComponent_2)), i1.ɵdid(12, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 2, "button", [["class", "default-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeAlert() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(14, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = (((_co.type === "error") && (_co.errorCode !== undefined)) && (_co.errorCode !== "")); _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.hasConfirmBtn; _ck(_v, 12, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, _ck(_v, 7, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.contentText)))); _ck(_v, 5, 0, currVal_1); var currVal_4 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform(_co.closeBtnText)); _ck(_v, 14, 0, currVal_4); }); }
export function View_AlertComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-alert", [], [[40, "@fade", 0]], null, null, View_AlertComponent_0, RenderType_AlertComponent)), i1.ɵdid(1, 114688, null, 0, i6.AlertComponent, [i7.AppOverlaysService, i8.AppLangService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).fade; _ck(_v, 0, 0, currVal_0); }); }
var AlertComponentNgFactory = i1.ɵccf("ra-alert", i6.AlertComponent, View_AlertComponent_Host_0, { type: "type", contentText: "contentText", hasConfirmBtn: "hasConfirmBtn", confirmBtnText: "confirmBtnText", closeBtnText: "closeBtnText", errorCode: "errorCode" }, { confirmBtnClick: "confirmBtnClick", closeBtnClick: "closeBtnClick" }, []);
export { AlertComponentNgFactory as AlertComponentNgFactory };
