/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payment-success.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "helio-games-core";
import * as i3 from "@angular/platform-browser";
import * as i4 from "@angular/common";
import * as i5 from "@ngx-translate/core";
import * as i6 from "@angular/router";
import * as i7 from "./payment-success.component";
import * as i8 from "../../shared/services/agent-balance.service";
import * as i9 from "../../app-header/app-lang-select/app-lang.service";
import * as i10 from "../../shared/services/auth.service";
var styles_PaymentSuccessComponent = [i0.styles];
var RenderType_PaymentSuccessComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaymentSuccessComponent, data: {} });
export { RenderType_PaymentSuccessComponent as RenderType_PaymentSuccessComponent };
export function View_PaymentSuccessComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.ɵcp, [i3.DomSanitizer]), i1.ɵpid(0, i2.ɵcn, []), i1.ɵpid(0, i4.CurrencyPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(3, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 5, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(7, 5), i1.ɵppd(8, 2), i1.ɵpod(9, { "newBalance": 0 }), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(11, 1), (_l()(), i1.ɵeld(12, 0, null, null, 5, "button", [["class", "default-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 16384, null, 0, i6.RouterLink, [i6.Router, i6.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(14, 1), (_l()(), i1.ɵeld(15, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, ["", ""])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_2 = _ck(_v, 14, 0, "/"); _ck(_v, 13, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("payments.payment-success")); _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 6, 0, _ck(_v, 11, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 10).transform("payments.payment-success-text", _ck(_v, 9, 0, i1.ɵunv(_v, 6, 0, _ck(_v, 8, 0, i1.ɵnov(_v, 1), i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v, 2), _co.newBalance, _co.currencyCode, "symbol", "1.0-2", _co.localeID)), _co.currencyCode))))))); _ck(_v, 6, 0, currVal_1); var currVal_3 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform("general.alerts.ok-btn")); _ck(_v, 16, 0, currVal_3); }); }
export function View_PaymentSuccessComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-payment-success", [], null, null, null, View_PaymentSuccessComponent_0, RenderType_PaymentSuccessComponent)), i1.ɵdid(1, 114688, null, 0, i7.PaymentSuccessComponent, [i8.AgentBalanceService, i9.AppLangService, i10.AuthService, i6.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaymentSuccessComponentNgFactory = i1.ɵccf("ra-payment-success", i7.PaymentSuccessComponent, View_PaymentSuccessComponent_Host_0, {}, {}, []);
export { PaymentSuccessComponentNgFactory as PaymentSuccessComponentNgFactory };
