/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./draw-stats-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../draw-stats-card/draw-stats-card.component.ngfactory";
import * as i3 from "../draw-stats-card/draw-stats-card.component";
import * as i4 from "../../app-header/app-lang-select/app-lang.service";
import * as i5 from "@angular/common";
import * as i6 from "@ngx-translate/core";
import * as i7 from "./draw-stats-list.component";
import * as i8 from "helio-games-core";
import * as i9 from "../../shared/services/auth.service";
import * as i10 from "../../shared/services/games.service";
var styles_DrawStatsListComponent = [i0.styles];
var RenderType_DrawStatsListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DrawStatsListComponent, data: {} });
export { RenderType_DrawStatsListComponent as RenderType_DrawStatsListComponent };
function View_DrawStatsListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-draw-stats-card", [], null, null, null, i2.View_DrawStatsCardComponent_0, i2.RenderType_DrawStatsCardComponent)), i1.ɵdid(1, 114688, null, 0, i3.DrawStatsCardComponent, [i4.AppLangService], { lastDrawData: [0, "lastDrawData"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_DrawStatsListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DrawStatsListComponent_2)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.gamesLastDraw; _ck(_v, 2, 0, currVal_0); }, null); }
function View_DrawStatsListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "loading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "loader"]], null, null, null, null, null))], null, null); }
function View_DrawStatsListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("draw-stats.get-data-error")); _ck(_v, 2, 0, currVal_0); }); }
function View_DrawStatsListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("draw-stats.no-data")); _ck(_v, 2, 0, currVal_0); }); }
export function View_DrawStatsListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "content-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "cards-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DrawStatsListComponent_1)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DrawStatsListComponent_3)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DrawStatsListComponent_4)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DrawStatsListComponent_5)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.gamesLastDraw.length > 0) && !_co.getDataError) && !_co.isLoading); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.isLoading; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.getDataError; _ck(_v, 7, 0, currVal_2); var currVal_3 = (((_co.gamesLastDraw.length === 0) && !_co.getDataError) && !_co.isLoading); _ck(_v, 9, 0, currVal_3); }, null); }
export function View_DrawStatsListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-draw-stats-list", [], null, null, null, View_DrawStatsListComponent_0, RenderType_DrawStatsListComponent)), i1.ɵdid(1, 114688, null, 0, i7.DrawStatsListComponent, [i8.AgentDrawHistoryService, i9.AuthService, i4.AppLangService, i10.GamesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DrawStatsListComponentNgFactory = i1.ɵccf("ra-draw-stats-list", i7.DrawStatsListComponent, View_DrawStatsListComponent_Host_0, {}, {}, []);
export { DrawStatsListComponentNgFactory as DrawStatsListComponentNgFactory };
