/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./agent-lobby.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./agent-lobby.component";
import * as i3 from "@angular/router";
import * as i4 from "../shared/services/auth.service";
import * as i5 from "../app-header/app-lang-select/app-lang.service";
var styles_AgentLobbyComponent = [i0.styles];
var RenderType_AgentLobbyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AgentLobbyComponent, data: {} });
export { RenderType_AgentLobbyComponent as RenderType_AgentLobbyComponent };
export function View_AgentLobbyComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { launchLobbyScriptContainer: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["id", "helio-lobby"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["launchLobbyScript", 1]], null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_AgentLobbyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-agent-lobby", [], null, [["window", "message"]], function (_v, en, $event) { var ad = true; if (("window:message" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).windowMessageHandler($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_AgentLobbyComponent_0, RenderType_AgentLobbyComponent)), i1.ɵdid(1, 4243456, null, 0, i2.AgentLobbyComponent, [i3.Router, i4.AuthService, i5.AppLangService], null, null)], null, null); }
var AgentLobbyComponentNgFactory = i1.ɵccf("ra-agent-lobby", i2.AgentLobbyComponent, View_AgentLobbyComponent_Host_0, {}, {}, []);
export { AgentLobbyComponentNgFactory as AgentLobbyComponentNgFactory };
