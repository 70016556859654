/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./scan-ticket.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../node_modules/helio-games-core/helio-games-core.ngfactory";
import * as i4 from "helio-games-core";
import * as i5 from "@angular/common";
import * as i6 from "../../../../node_modules/@zxing/ngx-scanner/zxing-ngx-scanner.ngfactory";
import * as i7 from "@zxing/ngx-scanner";
import * as i8 from "./scan-ticket.component";
import * as i9 from "../../shared/services/app-overlays.service";
import * as i10 from "../../shared/services/auth.service";
import * as i11 from "../../shared/services/error-handler.service";
var styles_ScanTicketComponent = [i0.styles];
var RenderType_ScanTicketComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ScanTicketComponent, data: { "animation": [{ type: 7, name: "fade", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: "250ms" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 6, styles: { opacity: 1 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: "250ms" }], options: null }], options: {} }] } });
export { RenderType_ScanTicketComponent as RenderType_ScanTicketComponent };
function View_ScanTicketComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 3, "hg-dropdown", [], null, [[null, "listSelectionChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("listSelectionChange" === en)) {
        var pd_0 = (_co.cameraSelectionChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ɵbn_0, i3.RenderType_ɵbn)), i1.ɵdid(5, 4833280, null, 2, i4.ɵbn, [i1.ChangeDetectorRef], { list: [0, "list"], labelProp: [1, "labelProp"], listSelection: [2, "listSelection"] }, { listSelectionChange: "listSelectionChange" }), i1.ɵqud(603979776, 1, { selectedItemTemplate: 0 }), i1.ɵqud(603979776, 2, { itemsTemplate: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.deviceCameras; var currVal_2 = "label"; var currVal_3 = _co.selectedDevice; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("scan-ticket.select-camera-lbl")); _ck(_v, 2, 0, currVal_0); }); }
function View_ScanTicketComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("scan-ticket.no-cameras")); _ck(_v, 1, 0, currVal_0); }); }
export function View_ScanTicketComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScanTicketComponent_1)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "zxing-scanner", [], null, [[null, "deviceChange"], [null, "camerasFound"], [null, "camerasNotFound"], [null, "scanSuccess"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("deviceChange" === en)) {
        var pd_0 = (_co.setCurrentCamera($event) !== false);
        ad = (pd_0 && ad);
    } if (("camerasFound" === en)) {
        var pd_1 = (_co.camerasFoundHandler($event) !== false);
        ad = (pd_1 && ad);
    } if (("camerasNotFound" === en)) {
        var pd_2 = (_co.camerasNotFoundHandler() !== false);
        ad = (pd_2 && ad);
    } if (("scanSuccess" === en)) {
        var pd_3 = (_co.scanSuccessHandler($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i6.View_ZXingScannerComponent_0, i6.RenderType_ZXingScannerComponent)), i1.ɵdid(7, 4374528, null, 0, i7.ZXingScannerComponent, [], { device: [0, "device"], formats: [1, "formats"], enable: [2, "enable"] }, { scanSuccess: "scanSuccess", camerasFound: "camerasFound", camerasNotFound: "camerasNotFound", deviceChange: "deviceChange" }), i1.ɵpad(8, 4), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScanTicketComponent_2)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 3, "footer", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "button", [["class", "default-btn cancel"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeScanTicket() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(13, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.multipleCameras; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.currentDevice; var currVal_3 = _ck(_v, 8, 0, "QR_CODE", "EAN_13", "CODE_128", "DATA_MATRIX"); var currVal_4 = _co.deviceEnabled; _ck(_v, 7, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = ((_co.noDevicesFound === true) && (_co.deviceCameras.length === 0)); _ck(_v, 10, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("scan-ticket.title")); _ck(_v, 2, 0, currVal_0); var currVal_6 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("general.alerts.cancel-btn")); _ck(_v, 13, 0, currVal_6); }); }
export function View_ScanTicketComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-scan-ticket", [], [[40, "@fade", 0]], null, null, View_ScanTicketComponent_0, RenderType_ScanTicketComponent)), i1.ɵdid(1, 245760, null, 0, i8.ScanTicketComponent, [i9.AppOverlaysService, i4.AgentCouponsService, i10.AuthService, i11.ErrorHandlerService, i2.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).fade; _ck(_v, 0, 0, currVal_0); }); }
var ScanTicketComponentNgFactory = i1.ɵccf("ra-scan-ticket", i8.ScanTicketComponent, View_ScanTicketComponent_Host_0, {}, {}, []);
export { ScanTicketComponentNgFactory as ScanTicketComponentNgFactory };
