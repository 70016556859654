/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./stats-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../stats-card/stats-card.component.ngfactory";
import * as i3 from "../stats-card/stats-card.component";
import * as i4 from "../../shared/services/auth.service";
import * as i5 from "../../app-header/app-lang-select/app-lang.service";
import * as i6 from "@angular/common";
import * as i7 from "@ngx-translate/core";
import * as i8 from "./stats-list.component";
import * as i9 from "helio-games-core";
var styles_StatsListComponent = [i0.styles];
var RenderType_StatsListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StatsListComponent, data: {} });
export { RenderType_StatsListComponent as RenderType_StatsListComponent };
function View_StatsListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-stats-card", [], null, null, null, i2.View_StatsCardComponent_0, i2.RenderType_StatsCardComponent)), i1.ɵdid(1, 114688, null, 0, i3.StatsCardComponent, [i4.AuthService, i5.AppLangService], { agentStat: [0, "agentStat"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_StatsListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatsListComponent_2)), i1.ɵdid(2, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stats; _ck(_v, 2, 0, currVal_0); }, null); }
function View_StatsListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "loading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "loader"]], null, null, null, null, null))], null, null); }
function View_StatsListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("sales-stats.get-data-error")); _ck(_v, 2, 0, currVal_0); }); }
function View_StatsListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("sales-stats.no-data")); _ck(_v, 2, 0, currVal_0); }); }
export function View_StatsListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "content-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "cards-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatsListComponent_1)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatsListComponent_3)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatsListComponent_4)), i1.ɵdid(7, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatsListComponent_5)), i1.ɵdid(9, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.stats.length > 0) && !_co.getDataError) && !_co.isLoading); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.isLoading; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.getDataError; _ck(_v, 7, 0, currVal_2); var currVal_3 = (((_co.stats.length === 0) && !_co.getDataError) && !_co.isLoading); _ck(_v, 9, 0, currVal_3); }, null); }
export function View_StatsListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-stats-list", [], null, null, null, View_StatsListComponent_0, RenderType_StatsListComponent)), i1.ɵdid(1, 638976, null, 0, i8.StatsListComponent, [i9.AgentSalesStatsService, i4.AuthService, i7.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StatsListComponentNgFactory = i1.ɵccf("ra-stats-list", i8.StatsListComponent, View_StatsListComponent_Host_0, { dateFrom: "dateFrom", dateTo: "dateTo" }, { onStatsReceived: "onStatsReceived" }, []);
export { StatsListComponentNgFactory as StatsListComponentNgFactory };
