/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./scanned-ticket.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./ticket-participation/ticket-participation.component.ngfactory";
import * as i3 from "./ticket-participation/ticket-participation.component";
import * as i4 from "../../app-header/app-lang-select/app-lang.service";
import * as i5 from "@angular/common";
import * as i6 from "@ngx-translate/core";
import * as i7 from "helio-games-core";
import * as i8 from "./scanned-ticket.component";
import * as i9 from "../../shared/services/agent-balance.service";
import * as i10 from "../../shared/services/app-overlays.service";
import * as i11 from "../../shared/services/auth.service";
import * as i12 from "../../shared/services/error-handler.service";
import * as i13 from "./cancel-ticket.service";
import * as i14 from "../../shared/services/capabilities.service";
var styles_ScannedTicketComponent = [i0.styles];
var RenderType_ScannedTicketComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ScannedTicketComponent, data: { "animation": [{ type: 7, name: "fade", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: "250ms" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 6, styles: { opacity: 1 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: "250ms" }], options: null }], options: {} }] } });
export { RenderType_ScannedTicketComponent as RenderType_ScannedTicketComponent };
function View_ScannedTicketComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ra-ticket-participation", [], [[2, "first-participation", null]], null, null, i2.View_TicketParticipationComponent_0, i2.RenderType_TicketParticipationComponent)), i1.ɵdid(2, 114688, null, 0, i3.TicketParticipationComponent, [i4.AppLangService], { ticketEntryNumber: [0, "ticketEntryNumber"], participationData: [1, "participationData"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.ticketEntryNum; var currVal_2 = _v.context.$implicit; _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = (_v.context.$implicit.ticketEntryNum === 1); _ck(_v, 1, 0, currVal_0); }); }
function View_ScannedTicketComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "participations-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScannedTicketComponent_2)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.scannedTicket.participations; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ScannedTicketComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵppd(5, 5), i1.ɵppd(6, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("scanned-ticket.tot-winnings-lbl")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent, 1), _co.scannedTicket.totalWinnings, _co.scannedTicket.currencyCode, "symbol", "1.0-2", _co.localeID)), _co.scannedTicket.currencyCode)); _ck(_v, 4, 0, currVal_1); }); }
function View_ScannedTicketComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("scanned-ticket.no-winnings")); _ck(_v, 2, 0, currVal_0); }); }
function View_ScannedTicketComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "default-btn"]], [[2, "is-loading", null], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelTicketConfirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isCancelLoading; var currVal_1 = !_co.cancelTicketEnabled; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("scanned-ticket.cancel-ticket-btn")); _ck(_v, 2, 0, currVal_2); }); }
export function View_ScannedTicketComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i7.ɵcn, []), i1.ɵpid(0, i5.CurrencyPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(2, 0, null, null, 26, "div", [["class", "overlay-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " - ", " "])), i1.ɵpod(5, { "gameName": 0 }), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(7, 5), i1.ɵppd(8, 2), (_l()(), i1.ɵeld(9, 0, null, null, 0, "button", [["class", "close-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeOverlay() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 5, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "comment"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScannedTicketComponent_1)), i1.ɵdid(15, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 12, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 4, "div", [["class", "tot-winnings"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScannedTicketComponent_3)), i1.ɵdid(20, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScannedTicketComponent_4)), i1.ɵdid(22, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScannedTicketComponent_5)), i1.ɵdid(24, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(25, 0, null, null, 3, "button", [["class", "default-btn"]], [[2, "is-loading", null], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.markAsPaid() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(27, null, ["", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_3 = (_co.scannedTicket.participations.length !== undefined); _ck(_v, 15, 0, currVal_3); var currVal_4 = _co.hasParticipationsToPay; _ck(_v, 20, 0, currVal_4); var currVal_5 = !_co.hasParticipationsToPay; _ck(_v, 22, 0, currVal_5); var currVal_6 = _co.cancelTicketEnabled; _ck(_v, 24, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 6).transform("scanned-ticket.title", _ck(_v, 5, 0, _co.scannedTicket.gameName))); var currVal_1 = i1.ɵunv(_v, 4, 1, _ck(_v, 8, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 4, 1, _ck(_v, 7, 0, i1.ɵnov(_v, 1), _co.scannedTicket.ticketCost, _co.scannedTicket.currencyCode, "symbol", "1.0-2", _co.localeID)), _co.scannedTicket.currencyCode)); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = _co.scannedTicket.comment; _ck(_v, 13, 0, currVal_2); var currVal_7 = _co.isMarkAsPaidLoading; var currVal_8 = !_co.hasParticipationsToPay; _ck(_v, 25, 0, currVal_7, currVal_8); var currVal_9 = i1.ɵunv(_v, 27, 0, i1.ɵnov(_v, 28).transform("scanned-ticket.mark-as-paid-btn")); _ck(_v, 27, 0, currVal_9); }); }
export function View_ScannedTicketComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-scanned-ticket", [], [[40, "@fade", 0]], null, null, View_ScannedTicketComponent_0, RenderType_ScannedTicketComponent)), i1.ɵdid(1, 245760, null, 0, i8.ScannedTicketComponent, [i7.AgentPlaceBetService, i9.AgentBalanceService, i10.AppOverlaysService, i11.AuthService, i12.ErrorHandlerService, i6.TranslateService, i4.AppLangService, i13.CancelTicketService, i14.CapabilitiesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).fade; _ck(_v, 0, 0, currVal_0); }); }
var ScannedTicketComponentNgFactory = i1.ɵccf("ra-scanned-ticket", i8.ScannedTicketComponent, View_ScannedTicketComponent_Host_0, { scannedTicket: "scannedTicket" }, {}, []);
export { ScannedTicketComponentNgFactory as ScannedTicketComponentNgFactory };
