/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./secure-content.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../app-header/app-header.component.ngfactory";
import * as i3 from "../../app-header/app-header.component";
import * as i4 from "../../shared/services/auth.service";
import * as i5 from "../../shared/services/agent-balance.service";
import * as i6 from "../../app-header/app-lang-select/app-lang.service";
import * as i7 from "../../shared/services/deposit.service";
import * as i8 from "../../shared/services/app-layout.service";
import * as i9 from "../../shared/services/capabilities.service";
import * as i10 from "../../nav/nav.component.ngfactory";
import * as i11 from "../../nav/nav.component";
import * as i12 from "../../shared/services/pwa.service";
import * as i13 from "@angular/router";
import * as i14 from "./secure-content.component";
import * as i15 from "../../shared/services/app-overlays.service";
var styles_SecureContentComponent = [i0.styles];
var RenderType_SecureContentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SecureContentComponent, data: {} });
export { RenderType_SecureContentComponent as RenderType_SecureContentComponent };
export function View_SecureContentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-app-header", [], null, null, null, i2.View_AppHeaderComponent_0, i2.RenderType_AppHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.AppHeaderComponent, [i4.AuthService, i5.AgentBalanceService, i6.AppLangService, i7.DepositService, i8.AppLayoutService, i9.CapabilitiesService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "ra-nav", [], null, null, null, i10.View_NavComponent_0, i10.RenderType_NavComponent)), i1.ɵdid(4, 114688, null, 0, i11.NavComponent, [i12.PwaService], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "page-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(7, 212992, null, 0, i13.RouterOutlet, [i13.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 4, 0); _ck(_v, 7, 0); }, null); }
export function View_SecureContentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-secure-content", [], null, null, null, View_SecureContentComponent_0, RenderType_SecureContentComponent)), i1.ɵdid(1, 4243456, null, 0, i14.SecureContentComponent, [i15.AppOverlaysService, i4.AuthService], null, null)], null, null); }
var SecureContentComponentNgFactory = i1.ɵccf("ra-secure-content", i14.SecureContentComponent, View_SecureContentComponent_Host_0, {}, {}, []);
export { SecureContentComponentNgFactory as SecureContentComponentNgFactory };
