import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "helio-games-core";
import * as i3 from "./auth.service";
export class DepositService {
    constructor(http, appConfigService, authService) {
        this.http = http;
        this.appConfigService = appConfigService;
        this.authService = authService;
    }
    createDeposit(gatewayName, currencyCode, idempotencyKey) {
        //
        const url = `${this.appConfigService.agentServiceBaseURL}api/deposits`;
        const authHeaderValue = `${this.authService.authTokenData.token_type} ${this.authService.authTokenData.access_token}`;
        return this.http.post(url, {
            gatewayName, currencyCode, idempotencyKey
        }, {
            headers: { 'Authorization': authHeaderValue }
        }).toPromise();
    }
    getIdempotencyKey() {
        const url = `${this.appConfigService.agentServiceBaseURL}api/deposits/idempotency`;
        return this.http.get(url).toPromise();
    }
}
DepositService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DepositService_Factory() { return new DepositService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AppConfigService), i0.ɵɵinject(i3.AuthService)); }, token: DepositService, providedIn: "root" });
