import * as i0 from "@angular/core";
export class CapabilitiesService {
    constructor() {
        this._capabilities = new Map();
        this.setCapabilities = (capabilities) => {
            capabilities.forEach(c => {
                let v = this.parseType(c.value, c.type);
                this._capabilities.set(c.name, v);
            });
            console.log(`Agent capabilities: ${capabilities.map(c => `${c.name}:${c.value}`).join(',')}`);
        };
        this.getCapability = (name) => {
            return this._capabilities.get(name);
        };
        this.parseType = (value, type) => {
            switch (type) {
                case 'bool': {
                    return value === "true";
                }
                case 'int': {
                    return parseInt(value);
                }
                case 'string': {
                    return value;
                }
            }
        };
    }
}
CapabilitiesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CapabilitiesService_Factory() { return new CapabilitiesService(); }, token: CapabilitiesService, providedIn: "root" });
