import { Component, Input, OnInit } from '@angular/core';
import { ScannedTicketParticipation } from '../../shared/models';
import { AppLangService } from '../../../app-header';

@Component({
	selector: 'ra-ticket-participation',
	templateUrl: './ticket-participation.component.html',
	styleUrls: ['./ticket-participation.component.scss']
})
export class TicketParticipationComponent implements OnInit {
	@Input() ticketEntryNumber: number;
	@Input() participationData: ScannedTicketParticipation;

	localeID: string;

	constructor(
		private appLangService: AppLangService
	) { }

	ngOnInit() {
		this.localeID = this.appLangService.selectedLocaleID;
	}
}
