<div class="wrapper">
	<h2>{{ 'change-password.title' | translate }}</h2>
	<div class="form-rules-wrapper">
		<div>
			<form [formGroup]="changePasswordForm" (ngSubmit)="changePassword()">
				<div>
					<label for="old-pass">{{ 'change-password.old-pass-lbl' | translate }}</label>
					<div>
						<input id="old-pass" formControlName="oldPassword" type="password">
					</div>
				</div>
				<div>
					<label for="new-pass">{{ 'change-password.new-pass-lbl' | translate }}</label>
					<div>
						<input id="new-pass" formControlName="newPassword" type="password"
							[class.invalid]="!changePasswordForm.get('newPassword').valid && !changePasswordForm.get('newPassword').pristine">
					</div>
				</div>
				<div>
					<label for="confirm-pass">{{ 'change-password.confirm-pass-lbl' | translate }}</label>
					<div>
						<input id="confirm-pass" formControlName="newPasswordConfirm" type="password"
							[class.invalid]="!changePasswordForm.get('newPasswordConfirm').valid && !changePasswordForm.get('newPasswordConfirm').pristine">
					</div>
				</div>
				<button type="submit" class="default-btn" [class.is-loading]="isLoading" [disabled]="!changePasswordForm.valid">
					<span>{{ 'change-password.change-pass-btn' | translate }}</span>
				</button>
			</form>
		</div>
		<div class="password-rules">
			<h3>{{ 'change-password.pass-rules' | translate }}</h3>
			<ul>
				<li>{{ 'change-password.password-rules.rule-1' | translate }}</li>
				<li>{{ 'change-password.password-rules.rule-2' | translate }}</li>
				<li>{{ 'change-password.password-rules.rule-3' | translate }}</li>
				<li>{{ 'change-password.password-rules.rule-4' | translate }}</li>
				<li>{{ 'change-password.password-rules.rule-5' | translate }}</li>
				<li>{{ 'change-password.password-rules.rule-6' | translate }}</li>
			</ul>
		</div>
	</div>
</div>