/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./print-ticket.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../print-content/print-content.component.ngfactory";
import * as i4 from "../print-content/print-content.component";
import * as i5 from "@angular/router";
import * as i6 from "../../../shared/services/auth.service";
import * as i7 from "../../../app-header/app-lang-select/app-lang.service";
import * as i8 from "helio-games-core";
import * as i9 from "../../buy-ticket.service";
import * as i10 from "../../../shared/services/capabilities.service";
import * as i11 from "@angular/common";
import * as i12 from "./print-ticket.component";
import * as i13 from "../../../shared/services/app-overlays.service";
import * as i14 from "../../../check-ticket/scanned-ticket/cancel-ticket.service";
import * as i15 from "../../../shared/services/error-handler.service";
var styles_PrintTicketComponent = [i0.styles];
var RenderType_PrintTicketComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PrintTicketComponent, data: { "animation": [{ type: 7, name: "fade", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: "250ms" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 6, styles: { opacity: 1 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: "250ms" }], options: null }], options: {} }] } });
export { RenderType_PrintTicketComponent as RenderType_PrintTicketComponent };
function View_PrintTicketComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "default-btn add-margin"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelTicketConfirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.canCancel; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("scanned-ticket.cancel-ticket-btn")); _ck(_v, 1, 0, currVal_1); }); }
function View_PrintTicketComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("purchase-print.printing")); _ck(_v, 1, 0, currVal_0); }); }
function View_PrintTicketComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("purchase-print.print-btn")); _ck(_v, 1, 0, currVal_0); }); }
function View_PrintTicketComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("purchase-print.reprint-btn")); _ck(_v, 1, 0, currVal_0); }); }
function View_PrintTicketComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "default-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.share() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("purchase-print.share-btn")); _ck(_v, 1, 0, currVal_0); }); }
export function View_PrintTicketComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { printIframeDiv: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 23, "div", [["class", "overlay-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 22, "div", [["class", "overflow-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["id", "ticket-to-print"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "ra-print-content", [], [[4, "padding", null]], null, null, i3.View_PrintContentComponent_0, i3.RenderType_PrintContentComponent)), i1.ɵdid(5, 114688, null, 0, i4.PrintContentComponent, [i5.ActivatedRoute, i6.AuthService, i7.AppLangService, i8.AppConfigService, i9.BuyTicketService, i8.GameFinancialRulesService, i10.CapabilitiesService, i8.GameService], { couponToPrint: [0, "couponToPrint"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 18, "div", [["class", "footer add-margin"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PrintTicketComponent_1)), i1.ɵdid(8, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 9, "button", [], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.print() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i11.ɵNgClassImpl, i11.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(12, 278528, null, 0, i11.NgClass, [i11.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(13, { "default-btn": 0, "secondary-btn": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PrintTicketComponent_2)), i1.ɵdid(15, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PrintTicketComponent_3)), i1.ɵdid(17, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PrintTicketComponent_4)), i1.ɵdid(19, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PrintTicketComponent_5)), i1.ɵdid(21, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(22, 0, null, null, 2, "button", [["class", "default-btn"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePrintTicket() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(23, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(25, 0, [[1, 0], ["printIframeDiv", 1]], null, 0, "div", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.couponToPrint; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.canCancel; _ck(_v, 8, 0, currVal_2); var currVal_4 = _ck(_v, 13, 0, (!_co.isPrinting || (_co.canReprint && !_co.hasClickedPrintBtn)), (_co.isPrinting || (!_co.canReprint && _co.hasClickedPrintBtn))); _ck(_v, 12, 0, currVal_4); var currVal_5 = _co.isPrinting; _ck(_v, 15, 0, currVal_5); var currVal_6 = ((!_co.isPrinting && !_co.canReprint) || (_co.canReprint && !_co.hasClickedPrintBtn)); _ck(_v, 17, 0, currVal_6); var currVal_7 = ((!_co.isPrinting && _co.hasClickedPrintBtn) && _co.canReprint); _ck(_v, 19, 0, currVal_7); var currVal_8 = _co.canShare; _ck(_v, 21, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 5).padding; _ck(_v, 4, 0, currVal_0); var currVal_3 = (_co.isPrinting || (!_co.canReprint && _co.hasClickedPrintBtn)); _ck(_v, 10, 0, currVal_3); var currVal_9 = (_co.isPrinting || (!_co.hasClickedPrintBtn && !_co.hasClickedShareBtn)); _ck(_v, 22, 0, currVal_9); var currVal_10 = i1.ɵunv(_v, 23, 0, i1.ɵnov(_v, 24).transform("purchase-print.done-btn")); _ck(_v, 23, 0, currVal_10); }); }
export function View_PrintTicketComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-print-ticket", [], [[40, "@fade", 0]], null, null, View_PrintTicketComponent_0, RenderType_PrintTicketComponent)), i1.ɵdid(1, 4308992, null, 0, i12.PrintTicketComponent, [i13.AppOverlaysService, i6.AuthService, i2.TranslateService, i14.CancelTicketService, i15.ErrorHandlerService, i9.BuyTicketService, i10.CapabilitiesService, i8.GameService, i8.GameFinancialRulesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).fade; _ck(_v, 0, 0, currVal_0); }); }
var PrintTicketComponentNgFactory = i1.ɵccf("ra-print-ticket", i12.PrintTicketComponent, View_PrintTicketComponent_Host_0, { couponToPrint: "couponToPrint" }, {}, []);
export { PrintTicketComponentNgFactory as PrintTicketComponentNgFactory };
