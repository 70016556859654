import { CanActivate } from '@angular/router';
import { of } from 'rxjs';

import { PwaService } from '../shared/services';

export class InstallAppGuard implements CanActivate {
	constructor(
		private pwaService: PwaService
	) { }

	canActivate() {
		const canAccessInstallPage = this.pwaService.promptEvent !== undefined;

		return of(canAccessInstallPage);
	}
}
