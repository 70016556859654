/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./buy-ticket.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./coupon-picker/coupon-picker.component.ngfactory";
import * as i4 from "./coupon-picker/coupon-picker.component";
import * as i5 from "./buy-ticket.service";
import * as i6 from "helio-games-core";
import * as i7 from "@angular/router";
import * as i8 from "../shared/services/capabilities.service";
import * as i9 from "./purchase-print/purchase-print.component.ngfactory";
import * as i10 from "./purchase-print/purchase-print.component";
import * as i11 from "../shared/services/auth.service";
import * as i12 from "../shared/services/app-overlays.service";
import * as i13 from "../shared/services/error-handler.service";
import * as i14 from "../shared/services/agent-balance.service";
import * as i15 from "../app-header/app-lang-select/app-lang.service";
import * as i16 from "./buy-ticket.component";
import * as i17 from "../shared/services/app-layout.service";
var styles_BuyTicketComponent = [i0.styles];
var RenderType_BuyTicketComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BuyTicketComponent, data: {} });
export { RenderType_BuyTicketComponent as RenderType_BuyTicketComponent };
export function View_BuyTicketComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { couponPicker: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 3, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpod(3, { gameName: 0 }), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 1, "ra-coupon-picker", [], [[8, "hidden", 0]], null, null, i3.View_CouponPickerComponent_0, i3.RenderType_CouponPickerComponent)), i1.ɵdid(6, 4308992, [[1, 4], ["scrollTo", 4]], 0, i4.CouponPickerComponent, [i5.BuyTicketService, i6.GameService, i7.Router, i8.CapabilitiesService], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "ra-purchase-print", [], null, [[null, "scrollIntoView"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("scrollIntoView" === en)) {
        var pd_0 = (_co.scrollIntoView() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_PurchasePrintComponent_0, i9.RenderType_PurchasePrintComponent)), i1.ɵdid(8, 245760, null, 0, i10.PurchasePrintComponent, [i6.GameService, i6.GameFinancialRulesService, i5.BuyTicketService, i11.AuthService, i12.AppOverlaysService, i13.ErrorHandlerService, i14.AgentBalanceService, i15.AppLangService, i2.TranslateService, i8.CapabilitiesService], null, { scrollIntoView: "scrollIntoView" })], function (_ck, _v) { _ck(_v, 6, 0); _ck(_v, 8, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 4).transform("buy-ticket.generate-ticket", _ck(_v, 3, 0, _co.currentGameGroup.name))); _ck(_v, 2, 0, currVal_0); var currVal_1 = (!_co.mobilePickNumbers && _co.isMobile); _ck(_v, 5, 0, currVal_1); }); }
export function View_BuyTicketComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-buy-ticket", [], null, null, null, View_BuyTicketComponent_0, RenderType_BuyTicketComponent)), i1.ɵdid(1, 245760, null, 0, i16.BuyTicketComponent, [i7.ActivatedRoute, i5.BuyTicketService, i6.GameService, i17.AppLayoutService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BuyTicketComponentNgFactory = i1.ɵccf("ra-buy-ticket", i16.BuyTicketComponent, View_BuyTicketComponent_Host_0, {}, {}, []);
export { BuyTicketComponentNgFactory as BuyTicketComponentNgFactory };
