/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./comment-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./comment-modal.component";
import * as i4 from "../buy-ticket/buy-ticket.service";
import * as i5 from "../shared/services/app-overlays.service";
var styles_CommentModalComponent = [i0.styles];
var RenderType_CommentModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CommentModalComponent, data: { "animation": [{ type: 7, name: "fade", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: "250ms" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 6, styles: { opacity: 1 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: "250ms" }], options: null }], options: {} }] } });
export { RenderType_CommentModalComponent as RenderType_CommentModalComponent };
export function View_CommentModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { commentInput: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "comment-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [["class", "icon-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "comment-input-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "label", [["for", "comment"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, [[1, 0], ["commentInput", 1]], null, 0, "input", [["class", "comment-input"], ["id", "comment"]], null, [[null, "input"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (_co.onCommentUpdate($event.target.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "button", [["class", "default-btn"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.buy() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("purchase-print.customer-name")); _ck(_v, 4, 0, currVal_0); var currVal_1 = (!_co.commentText || (_co.commentText.length === 0)); _ck(_v, 9, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("purchase-print.purchase-and-print-btn")); _ck(_v, 11, 0, currVal_2); }); }
export function View_CommentModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-comment-modal", [], null, null, null, View_CommentModalComponent_0, RenderType_CommentModalComponent)), i1.ɵdid(1, 4308992, null, 0, i3.CommentModalComponent, [i4.BuyTicketService, i5.AppOverlaysService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CommentModalComponentNgFactory = i1.ɵccf("ra-comment-modal", i3.CommentModalComponent, View_CommentModalComponent_Host_0, {}, { comment: "comment" }, []);
export { CommentModalComponentNgFactory as CommentModalComponentNgFactory };
