import { Component, OnInit } from '@angular/core';
import { TenantRetailCapability } from '../shared/models/capability.model';

import { AuthService, AgentBalanceService, AppLayoutService } from '../shared/services';
import { CapabilitiesService } from '../shared/services/capabilities.service';
import { DepositService } from '../shared/services/deposit.service';
import { AppLangService } from './app-lang-select';

@Component({
	selector: 'ra-app-header',
	templateUrl: './app-header.component.html',
	styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit {

	agentFullName: string;
	agentID: string;
	currencyCode: string;
	paymentGateway: string;
	idempotencyKey: string;
	depositing: boolean;
	redirecting: boolean;
	balance = 0;

	now: Date;

	localeID: string;

	isMobile: boolean;
	languages: string[];

	constructor(
		private authService: AuthService,
		private agentBalanceService: AgentBalanceService,
		private appLangService: AppLangService,
		private depositService: DepositService,
		private appLayoutService: AppLayoutService,
		private capabilities: CapabilitiesService
	) { }

	ngOnInit() {
		this.localeID = this.appLangService.selectedLocaleID;		
		this.agentFullName = this.authService.authTokenData.payload.FullName;
		this.agentID = this.authService.authTokenData.payload.RetailAgentID;
		this.currencyCode = this.authService.authTokenData.payload.CurrencyCode;
		this.paymentGateway = this.authService.authTokenData.payload.PaymentGateway;		

		let caps = JSON.parse(this.authService.authTokenData.payload.Capabilities);
		this.capabilities.setCapabilities(caps);

		this.languages = this.getAvailableLanguages(this.capabilities.getCapability('languages'));
		console.log('languages length: ' + this.languages.length);

		this.balance = this.agentBalanceService.balance;
		this.agentBalanceService.onAgentBalanceChange.subscribe(newBalance => {
			this.balance = newBalance;
		});
		
		this.isMobile = this.appLayoutService.isMobile;

		this.now = new Date();

		setInterval(() => {
			this.now = new Date();
		}, 1000);

		if (this.paymentGateway) {
			this.depositService.getIdempotencyKey()
				.then(data => {
					this.idempotencyKey = data;
				});
		}
	}

	deposit() {

		if (!this.paymentGateway) {
			return;
		}

		this.depositing = true;

		this.depositService.createDeposit(this.paymentGateway, this.currencyCode, this.idempotencyKey)
			.then(data => {
				this.depositing = false;
				this.redirecting = true;
				window.location.href = data.url;
			});
	}

	getAvailableLanguages = (langs: string): string[] => {
		return langs.split(',');
	}

	logout() {
		this.authService.logout();
	}

}
