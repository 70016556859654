import { environment } from '../../../environments/environment';

export const getGameServer = (clusterID: string) => {
	switch (environment.env) {
		case 'prod':
			return `prod-${clusterID}`;
		case 'stage':
			return `stg-${clusterID}`;
		case 'qa':
			return `qa-${clusterID}`;
		case 'dev':
		default:
			return `dev`;
	}
};
