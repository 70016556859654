import DomToImage from 'dom-to-image';
import { saveAs } from 'file-saver';

import { Component, OnInit, Input, HostBinding, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { PurchasedTicket } from './ticket-data.model';
import { appOverlaysAnimations } from '../../../app-overlays.animations';
import { AuthService, AppOverlaysService, ErrorHandlerService } from '../../../shared/services';
import { AlertComponent } from '../../../alert';
import { CancelTicketService } from '../../../check-ticket/scanned-ticket';
import { BuyTicketService } from '../../buy-ticket.service';
import { CapabilitiesService } from 'src/app/shared/services/capabilities.service';
import { GameFinancialRulePrizeCategory, GameFinancialRulesService, GameService } from 'helio-games-core';
import * as _ from 'lodash';
import { CouponToPrint } from './coupon.model';

@Component({
	selector: 'ra-print-ticket',
	templateUrl: './print-ticket.component.html',
	styleUrls: ['./print-ticket.component.scss'],
	animations: appOverlaysAnimations
})
export class PrintTicketComponent implements OnInit, AfterViewInit {

	canShare: boolean = true;
	canReprint: boolean = false;
	canCancel: boolean = true;

	agentID: string;
	tenantName: string;
	comment: string;
	reprints: number = 0;

	isCancelLoading = false;
	isPrinting: boolean = false;

	@HostBinding('@fade') fade = true;
	@Input() couponToPrint: CouponToPrint = {
		maxWin: 0,
		tickets: [{
			agentID: '1',
			tenantName: 'Helio Gaming',
			termsAndConditions: '',
			gameGroupCode: 'default',
			gameTier: '',
			isDefaultGame: true,
			couponID: '12345',
			couponIdentifier: '1234567890123456',
			friendlyCouponIdentifier: '1234 5678 9012 3456',
			currencyCode: 'EUR',
			drawsFromTo: [1, 2],
			firstDrawDate: new Date(),
			gameName: 'Test Game',
			selectedNumbers: [
				[
					['1', '2', '3', '4', '5', '6'],
					['3', '4']
				],
				[
					['1', '2', '3', '4', '5', '6'],
					['3', '4']
				],
				[
					['1', '2', '3', '4', '5', '6'],
					['3', '4']
				],
				[
					['1', '2', '3', '4', '5', '6'],
					['3', '4']
				]
			],
			ticketCost: 1,
			ticketValidForDraws: 1,
			timestamp: new Date(),
			comment: 'Test User'
		}]
	};

	hasClickedPrintBtn = false;
	hasClickedShareBtn = false;

	@ViewChild('printIframeDiv', { static: false }) printIframeDiv: ElementRef;

	private iframeElement: HTMLIFrameElement;

	constructor(
		private appOverlaysService: AppOverlaysService,
		private authService: AuthService,
		private translateService: TranslateService,
		private cancelTicketService: CancelTicketService,
		private errorHandlerService: ErrorHandlerService,
		private buyTicketService: BuyTicketService,
		private capabilities: CapabilitiesService,
		private gameService: GameService,
		private gameFinancialRulesService: GameFinancialRulesService
	) { }

	ngOnInit() {
		this.canShare = this.capabilities.getCapability('share');
		this.canReprint = this.capabilities.getCapability('reprint');
		this.canCancel = this.capabilities.getCapability('cancel');
	}

	ngAfterViewInit() {
		//this.createPrintIframe();
	}

	print() {
		if (!this.isPrinting){
			if (!this.authService.isLoggedIn && !this.authService.canRefreshToken) {
				this.authService.logout();
			}
		
			this.isPrinting = true;
			if (!this.hasClickedPrintBtn) {
				this.hasClickedPrintBtn = true;
			} else {
				this.buyTicketService.reprint();						
			}		

			this.createPrintIframe();

			this.checkIframeLoadedandPrint();
		}
	}
	
	private checkIframeLoadedandPrint() {
		// Get a handle to the iframe element
		var iframe = this.iframeElement;
		var hasLoadedData:boolean = false;
		
		// In this section we are searching for the price element to be loaded in the iFrame
		if (this.iframeElement.contentWindow.document.children.length > 0)
			hasLoadedData = this.iframeElement.contentWindow.document.children[0].getElementsByClassName('price').length > 0;
		
		// Check if loading is complete
		if (  hasLoadedData ) {
			//a timeout is added so that if some elements in Angular are still not loaded, then wait 1 second for them to load
			setTimeout(() => {
				this.isPrinting = false;
				this.iframeElement.contentWindow.print();
			}, 1000);
			
			// If it has loaded return so that status is not checked again
			return;
		} 
	
		// If we are here, it is not loaded. Set things up so we check  the status again in 100 milliseconds
		window.setTimeout(()=>{ this.checkIframeLoadedandPrint(); }, 100);
	}

	share() {

		const node = document.getElementById('ticket-to-print');
		DomToImage.toBlob(node)
			.then((blob) => {
				const filename = `ticket - ${this.couponToPrint.tickets[0].friendlyCouponIdentifier}.png`;

				var file = new File([blob], filename, { type: 'image/png' });
				var filesArray = [file];

				const navigator = (window.navigator as any);

				if (navigator.share) {
					if (navigator.canShare && navigator.canShare({ files: filesArray })) {
						navigator.share({
							text: ` Your ${this.couponToPrint.tickets[0].gameName} Ticket`,
							files: filesArray,
							title: `${this.couponToPrint.tickets[0].tenantName} - Lottery Ticket`
						});
					}
				}
				else {
					saveAs(blob, filename);
				}

				this.hasClickedShareBtn = true;
			});
	}

	cancelTicketConfirm() {
		this.appOverlaysService.loadOverlay(AlertComponent, (alertComponent: AlertComponent) => {
			alertComponent.type = 'info';
			alertComponent.contentText = this.translateService.instant('scanned-ticket.cancel-ticket-confirm');

			alertComponent.hasConfirmBtn = true;
			alertComponent.confirmBtnText = this.translateService.instant('general.alerts.yes-btn');
			alertComponent.confirmBtnClick.subscribe(() => {
				this.cancelTicket();
				this.appOverlaysService.removeOverlay();
			});

			alertComponent.closeBtnText = this.translateService.instant('general.alerts.no-btn');
		});
	}

	closePrintTicket() {
		if (this.hasClickedPrintBtn || this.hasClickedShareBtn) {
			this.appOverlaysService.removeOverlay();
		}
	}

	private cancelTicket() {
		if (!this.isCancelLoading) {
			this.isCancelLoading = true;

			this.cancelTicketService.cancelTicket(this.couponToPrint.tickets[0].couponIdentifier)
				.subscribe(isSuccess => {
					this.isCancelLoading = false;
					if (isSuccess) {
						this.appOverlaysService.removeOverlay();
						this.appOverlaysService.loadOverlay(AlertComponent, (alertComponent: AlertComponent) => {
							alertComponent.type = 'info';
							alertComponent.contentText = this.translateService.instant('scanned-ticket.messages.ticket-cancel-success');
						});
					} else {
						this.appOverlaysService.loadOverlay(AlertComponent, (alertComponent: AlertComponent) => {
							alertComponent.type = 'error';
							alertComponent.contentText = this.translateService.instant('scanned-ticket.messages.ticket-cancel-error');
						});
					}
				}, error => {
					this.isCancelLoading = false;
					this.errorHandlerService.handleError(error, this.translateService.instant('scanned-ticket.messages.ticket-cancel-error'));
				});
		}
	}

	private createPrintIframe() {

		this.couponToPrint.maxWin = this.getMaxWin();

		const ticketData = btoa(JSON.stringify(this.couponToPrint));

		const iframeTarget: HTMLElement = this.printIframeDiv.nativeElement;

		while (iframeTarget.firstChild) {
			iframeTarget.removeChild(iframeTarget.firstChild);
		}

		let iframe = document.createElement('iframe');		

		iframe.id = 'ticket-content';
		iframe.src = `${window.location.origin}/#/print/${ticketData}`;
		iframe.style.position = 'absolute';
		iframe.style.opacity = '0';
		iframe.style.visibility = 'hidden';

		this.iframeElement = iframeTarget.appendChild(iframe);
	}

	public getMaxWin = (): number => {
		let currentTotal = this.getMaxWinFromSelectedTickets();

		const tickets = this.couponToPrint.tickets;
		let gameTiers = _.uniq(tickets.map(t => t.gameTier));
		let financialRules = gameTiers.map(t => this.getGameFinancialRuleFromTierName(t));

		let currentFinancialRule = this.buyTicketService.buyTicketGameData.gameFinancialRule;

		if (!financialRules.find(t => t.gameFinancialRuleID === currentFinancialRule.gameFinancialRuleID)) {
			currentTotal += this.getMaxWinFromFinancialRule(currentFinancialRule.gameFinancialRuleID);
		}

		return currentTotal;
	}

	public getMaxWinFromFinancialRule = (financialRuleID: number = undefined): number => {

		const financialRule = financialRuleID === undefined
			? this.buyTicketService.buyTicketGameData.gameFinancialRule
			: this.gameFinancialRulesService.gameFinancialRules.find(f => f.gameFinancialRuleID === financialRuleID);

		let jackpots: GameFinancialRulePrizeCategory;
		let maxPrize: number = 0;

		if (financialRule.prizeCategories.length > 0) {
			jackpots = financialRule.prizeCategories.find(p => p.prizeCategoryID === 1);

			if (jackpots !== undefined) {
				let prize = jackpots.amounts.find(p => p.currencyCode === this.couponToPrint.tickets[0].currencyCode);

				maxPrize = prize.prizeAmount == null
					? prize.prizeAmount
					: prize.minPrizeAmount;
			}
		}

		return maxPrize;
	}

	public getMaxWinFromSelectedTickets = (): number => {
		const tickets = this.couponToPrint.tickets;
		let gameTiers = _.uniq(tickets.map(t => t.gameTier));
		let financialRules = gameTiers.map(t => this.getGameFinancialRuleFromTierName(t));

		return financialRules.reduce((total, rule) => {
			let jackpot = rule.prizeCategories.find(prize => prize.prizeCategoryID === 1);
			let jackpotInSelectedCurrency = jackpot.amounts.find(j => j.currencyCode === this.couponToPrint.tickets[0].currencyCode);

			let prize = jackpotInSelectedCurrency.prizeAmount == null
				? jackpotInSelectedCurrency.prizeAmount
				: jackpotInSelectedCurrency.minPrizeAmount;

			return total + prize;
		}, 0);
	};

	private getGameFinancialRuleFromTierName = (tierName: string) => {
		let game = this.gameService.games.find(g => g.name == tierName);
		return this.gameFinancialRulesService.gameFinancialRules.find(gfr => gfr.gameID == game.gameID);
	}

}
