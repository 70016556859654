/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sales-stats.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./stats-list/stats-list.component.ngfactory";
import * as i5 from "./stats-list/stats-list.component";
import * as i6 from "helio-games-core";
import * as i7 from "../shared/services/auth.service";
import * as i8 from "@angular/platform-browser";
import * as i9 from "./sales-stats.component";
import * as i10 from "../app-header/app-lang-select/app-lang.service";
var styles_SalesStatsComponent = [i0.styles];
var RenderType_SalesStatsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SalesStatsComponent, data: {} });
export { RenderType_SalesStatsComponent as RenderType_SalesStatsComponent };
function View_SalesStatsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "filter-btn"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.filterClickHandler(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.name === _co.currentFilter.name); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 1, 0, currVal_1); }); }
function View_SalesStatsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "filters-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "filters"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SalesStatsComponent_2)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 11, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpod(7, { filterName: 0 }), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(9, 1), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["class", "separator"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0|\u00A0"])), (_l()(), i1.ɵeld(12, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 3, "i", [], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", " - ", ""])), i1.ɵppd(15, 4), i1.ɵppd(16, 4), (_l()(), i1.ɵeld(17, 0, null, null, 1, "ra-stats-list", [], null, [[null, "onStatsReceived"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onStatsReceived" === en)) {
        var pd_0 = (_co.onSalesStatsReceived($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_StatsListComponent_0, i4.RenderType_StatsListComponent)), i1.ɵdid(18, 638976, null, 0, i5.StatsListComponent, [i6.AgentSalesStatsService, i7.AuthService, i3.TranslateService], { dateFrom: [0, "dateFrom"], dateTo: [1, "dateTo"] }, { onStatsReceived: "onStatsReceived" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filters; _ck(_v, 4, 0, currVal_0); var currVal_4 = _co.currentFilter.dateFrom; var currVal_5 = _co.currentFilter.dateTo; _ck(_v, 18, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 6, 0, _ck(_v, 9, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 8).transform("sales-stats.stats-subtitle", _ck(_v, 7, 0, _co.currentFilter.name))))); _ck(_v, 6, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 14, 0, _ck(_v, 15, 0, i1.ɵnov(_v.parent, 1), _co.currentFilter.dateFrom, "medium", undefined, _co.localeID)); var currVal_3 = i1.ɵunv(_v, 14, 1, _ck(_v, 16, 0, i1.ɵnov(_v.parent, 1), _co.currentFilter.dateTo, "medium", undefined, _co.localeID)); _ck(_v, 14, 0, currVal_2, currVal_3); }); }
export function View_SalesStatsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i6.ɵcp, [i8.DomSanitizer]), i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), i1.ɵqud(671088640, 1, { printSalesIframeDiv: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 9, "div", [["class", "page-btns"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 5, "button", [], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.print() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(9, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(10, { "default-btn": 0, "secondary-btn": 1 }), (_l()(), i1.ɵted(11, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(13, 0, null, null, 2, "button", [["class", "default-btn"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.refreshFilters() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(14, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SalesStatsComponent_1)), i1.ɵdid(17, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(18, 0, [[1, 0], ["printSalesIframeDiv", 1]], null, 0, "div", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 10, 0, !_co.isPrinting, _co.isPrinting); _ck(_v, 9, 0, currVal_2); var currVal_6 = (_co.filters.length > 0); _ck(_v, 17, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("sales-stats.title")); _ck(_v, 4, 0, currVal_0); var currVal_1 = ((_co.currentSalesStats.length === 0) || _co.isPrinting); _ck(_v, 7, 0, currVal_1); var currVal_3 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("purchase-print.print-btn")); _ck(_v, 11, 0, currVal_3); var currVal_4 = _co.blockRefreshFilters; _ck(_v, 13, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("sales-stats.refresh-data-btn")); _ck(_v, 14, 0, currVal_5); }); }
export function View_SalesStatsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-sales-stats", [], null, null, null, View_SalesStatsComponent_0, RenderType_SalesStatsComponent)), i1.ɵdid(1, 114688, null, 0, i9.SalesStatsComponent, [i3.TranslateService, i10.AppLangService, i7.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SalesStatsComponentNgFactory = i1.ɵccf("ra-sales-stats", i9.SalesStatsComponent, View_SalesStatsComponent_Host_0, {}, {}, []);
export { SalesStatsComponentNgFactory as SalesStatsComponentNgFactory };
