/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app-lang-select.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/helio-games-core/helio-games-core.ngfactory";
import * as i3 from "helio-games-core";
import * as i4 from "@angular/common";
import * as i5 from "./app-lang-select.component";
import * as i6 from "./app-lang.service";
import * as i7 from "../../shared/services/capabilities.service";
var styles_AppLangSelectComponent = [i0.styles];
var RenderType_AppLangSelectComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppLangSelectComponent, data: {} });
export { RenderType_AppLangSelectComponent as RenderType_AppLangSelectComponent };
function View_AppLangSelectComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "hg-dropdown", [], null, [[null, "listSelectionChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("listSelectionChange" === en)) {
        var pd_0 = (_co.languageChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ɵbn_0, i2.RenderType_ɵbn)), i1.ɵdid(1, 4833280, null, 2, i3.ɵbn, [i1.ChangeDetectorRef], { list: [0, "list"], labelProp: [1, "labelProp"], listSelection: [2, "listSelection"] }, { listSelectionChange: "listSelectionChange" }), i1.ɵqud(603979776, 1, { selectedItemTemplate: 0 }), i1.ɵqud(603979776, 2, { itemsTemplate: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languageList; var currVal_1 = "name"; var currVal_2 = _co.selectedLanguage; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_AppLangSelectComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppLangSelectComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.languageList.length > 1); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AppLangSelectComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-language-select", [], null, null, null, View_AppLangSelectComponent_0, RenderType_AppLangSelectComponent)), i1.ɵdid(1, 4308992, null, 0, i5.AppLangSelectComponent, [i6.AppLangService, i3.AppConfigService, i7.CapabilitiesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppLangSelectComponentNgFactory = i1.ɵccf("ra-language-select", i5.AppLangSelectComponent, View_AppLangSelectComponent_Host_0, {}, {}, []);
export { AppLangSelectComponentNgFactory as AppLangSelectComponentNgFactory };
