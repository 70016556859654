<div class="participation-nums">
	<span class="participation-letter">{{ (65 + (ticketEntryNumber - 1)) | numberToChar }}.</span>
	<ng-container *ngFor="let ticketBoards of participationData.selectedNumbers; let i = index">
		<span class="board-separator" *ngIf="i > 0"> + </span>
		<ng-container *ngFor="let boardNumbers of ticketBoards">
			<span class="number" [class.matched]="boardNumbers.isMatched" [class.dark]="i % 2 !== 0">
				{{ boardNumbers.number }}
			</span>
		</ng-container>
	</ng-container>
</div>

<div class="info">
	<div>
		<b>{{ 'scanned-ticket.participation.draw-closed-lbl' | translate }}</b>
		<span class="boolean-value" [class.true]="participationData.isDrawClosed" [class.false]="!participationData.isDrawClosed"></span>
	</div>

	<div *ngIf="participationData.isDrawClosed">
		<b>{{ 'scanned-ticket.participation.draw-result-lbl' | translate }}</b>
		<ng-container *ngIf="!participationData.isDrawResultPending">
			<ng-container *ngFor="let drawResultBoards of participationData.drawResult; let i = index">
				<span *ngIf="i > 0"> + </span>
				<ng-container *ngFor="let drawResultNumber of drawResultBoards">
					{{ drawResultNumber }}
				</ng-container>
			</ng-container>
		</ng-container>
		<ng-container *ngIf="participationData.isDrawResultPending">
			{{ 'scanned-ticket.participation.draw-result-pending' | translate }}
		</ng-container>
	</div>

	<div>
		<b>{{ 'scanned-ticket.participation.draw-date-lbl' | translate }}</b> {{ participationData.drawDate | date:'short':undefined:localeID }}
	</div>

	<div *ngIf="participationData.isDrawClosed">
		<b>{{ 'scanned-ticket.participation.winnings-lbl' | translate }}</b>
		<ng-container *ngIf="participationData.isPaidOut !== undefined">
			{{ participationData.winAmount | currencyWithSpace:participationData.currencyCode:'symbol':undefined:localeID | hgReplaceCurrencyCode:participationData.currencyCode }}
		</ng-container>
		<ng-container *ngIf="participationData.isPaidOut === undefined">
			{{ 'scanned-ticket.participation.no-winnings' | translate }}
		</ng-container>
	</div>

	<div *ngIf="participationData.isDrawClosed && participationData.isPaidOut !== undefined && participationData.winAuthorisationStatus === 0">
		<b>{{ 'scanned-ticket.participation.winnings-paid-lbl' | translate }}</b>
		<span class="boolean-value" [class.true]="participationData.isPaidOut" [class.false]="!participationData.isPaidOut"></span>
	</div>

	<div class="win-auth" *ngIf="participationData.isDrawClosed && participationData.winAuthorisationStatus > 0">
		<ng-container [ngSwitch]="participationData.winAuthorisationStatus">
			<b *ngSwitchCase="1">{{ 'scanned-ticket.participation.win-auth.pending' | translate }}</b>
			<b *ngSwitchCase="2">{{ 'scanned-ticket.participation.win-auth.approved' | translate }}</b>
			<b *ngSwitchCase="3">{{ 'scanned-ticket.participation.win-auth.rejected' | translate }}</b>
		</ng-container>
	</div>
</div>
