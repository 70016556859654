import { Subject, BehaviorSubject } from 'rxjs';
import { GameFinancialRule, GameFinancialRulesService, GameService, TicketSelectedItems } from 'helio-games-core';
import * as i0 from "@angular/core";
import * as i1 from "helio-games-core";
export class BuyTicketService {
    constructor(gameService, gameFinancialRulesService) {
        this.gameService = gameService;
        this.gameFinancialRulesService = gameFinancialRulesService;
        this.ticketSelectedItems = [];
        this.reprints = new BehaviorSubject(0);
        this._reprints = 0;
        this.commentSubject = new Subject();
        this.consecutiveDrawsValue = 1;
        this.isQuickBuyReadyValue = false;
        this.isMobilePickNumbersValue = false;
        this.gameFinancialRuleChangeSubject = new Subject();
        this.ticketSelectedItemsChangeSubject = new Subject();
        this.consecutiveDrawsChangeSubject = new Subject();
        this.isQuickBuyReadyChangeSubject = new Subject();
        this.addRandomSubject = new Subject();
        this.pickNumbersSubject = new Subject();
        this.selectedGameSubscription = this.gameService.selectedGameChange.subscribe((game) => {
            this.buyTicketGameDataValue.gameFinancialRule = this.gameFinancialRulesService.gameFinancialRules.find(gfr => gfr.gameID == game.gameID);
            this.gameFinancialRuleChangeSubject.next(this.buyTicketGameDataValue.gameFinancialRule);
        });
    }
    addPickedItems(newPickedItems) {
        this.ticketSelectedItems.push(newPickedItems);
        this.ticketSelectedItemsChangeSubject.next();
    }
    removePickedItems(index) {
        if (index === undefined) {
            this.ticketSelectedItems.splice(0);
        }
        else {
            this.ticketSelectedItems.splice(index, 1);
        }
        this.ticketSelectedItemsChangeSubject.next();
    }
    get ticketSelectedItemsChange() {
        return this.ticketSelectedItemsChangeSubject.asObservable();
    }
    get consecutiveDrawsChange() {
        return this.consecutiveDrawsChangeSubject.asObservable();
    }
    get isQuickBuyReadyChange() {
        return this.isQuickBuyReadyChangeSubject.asObservable();
    }
    get gameFinancialRuleChange() {
        return this.gameFinancialRuleChangeSubject.asObservable();
    }
    get commentUpdate() {
        return this.commentSubject.asObservable();
    }
    get addRandomChange() {
        return this.addRandomSubject.asObservable();
    }
    get pickNumbersChange() {
        return this.pickNumbersSubject.asObservable();
    }
    get buyTicketGameData() {
        return this.buyTicketGameDataValue;
    }
    set buyTicketGameData(newBuyTicketGameData) {
        this.buyTicketGameDataValue = newBuyTicketGameData;
    }
    get consecutiveDraws() {
        return this.consecutiveDrawsValue;
    }
    set consecutiveDraws(newConsecutiveDrawsValue) {
        this.consecutiveDrawsValue = newConsecutiveDrawsValue;
        this.consecutiveDrawsChangeSubject.next();
    }
    get isQuickBuyReady() {
        return this.isQuickBuyReadyValue;
    }
    set isQuickBuyReady(newIsQuickBuyReadyValue) {
        this.isQuickBuyReadyValue = newIsQuickBuyReadyValue;
        this.isQuickBuyReadyChangeSubject.next(this.isQuickBuyReadyValue);
    }
    get comment() {
        return this._comment;
    }
    set comment(c) {
        this._comment = c;
        this.commentSubject.next(c);
    }
    addRandom() {
        this.addRandomSubject.next();
    }
    get isMobilePickNumbers() {
        return this.isMobilePickNumbersValue;
    }
    set isMobilePickNumbers(isMobilePickNumbers) {
        this.isMobilePickNumbersValue = isMobilePickNumbers;
        this.pickNumbersSubject.next(isMobilePickNumbers);
    }
    reprint() {
        this._reprints++;
        this.reprints.next(this._reprints);
        localStorage.setItem('ticket-reprints', this._reprints.toFixed(0));
    }
    resetReprints() {
        this._reprints = 0;
        this.reprints.next(this._reprints);
        localStorage.setItem('ticket-reprints', '0');
    }
}
BuyTicketService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BuyTicketService_Factory() { return new BuyTicketService(i0.ɵɵinject(i1.GameService), i0.ɵɵinject(i1.GameFinancialRulesService)); }, token: BuyTicketService, providedIn: "root" });
