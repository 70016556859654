/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./draw-stats.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "helio-games-core";
import * as i3 from "@angular/platform-browser";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./draw-stats-list/draw-stats-list.component.ngfactory";
import * as i6 from "./draw-stats-list/draw-stats-list.component";
import * as i7 from "../shared/services/auth.service";
import * as i8 from "../app-header/app-lang-select/app-lang.service";
import * as i9 from "../shared/services/games.service";
import * as i10 from "./draw-stats.component";
import * as i11 from "../shared/services/capabilities.service";
var styles_DrawStatsComponent = [i0.styles];
var RenderType_DrawStatsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DrawStatsComponent, data: {} });
export { RenderType_DrawStatsComponent as RenderType_DrawStatsComponent };
export function View_DrawStatsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.ɵcp, [i3.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "span", [["class", "subtitle"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), i1.ɵppd(7, 1), (_l()(), i1.ɵeld(8, 0, null, null, 1, "ra-draw-stats-list", [], null, null, null, i5.View_DrawStatsListComponent_0, i5.RenderType_DrawStatsListComponent)), i1.ɵdid(9, 114688, null, 0, i6.DrawStatsListComponent, [i2.AgentDrawHistoryService, i7.AuthService, i8.AppLangService, i9.GamesService], null, null)], function (_ck, _v) { _ck(_v, 9, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("draw-stats.title")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, _ck(_v, 7, 0, i1.ɵnov(_v, 0), i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("draw-stats.subtitle")))); _ck(_v, 5, 0, currVal_1); }); }
export function View_DrawStatsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-draw-stats", [], null, null, null, View_DrawStatsComponent_0, RenderType_DrawStatsComponent)), i1.ɵdid(1, 114688, null, 0, i10.DrawStatsComponent, [i11.CapabilitiesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DrawStatsComponentNgFactory = i1.ɵccf("ra-draw-stats", i10.DrawStatsComponent, View_DrawStatsComponent_Host_0, {}, {}, []);
export { DrawStatsComponentNgFactory as DrawStatsComponentNgFactory };
