import {environment} from 'src/environments/environment';

import {Buffer} from 'buffer';

export class AuthToken {
	access_token: string;
	token_type: string;
	expires_in: number;
	refresh_token: string;
	client_id: string;
	userName: string;
	betRequiresComment: boolean;
	paymentGateway: string;
	'.issued': Date;
	'.expires': Date;

	tokenExpiresDate: Date;

	payload: {
		RetailAgentID: string;
		unqiue_name: string;
		ClusterID: string;
		APIKey: string;
		TenantName: string;
		PlayerID: string;
		Logo: string;
		FullName: string;
		Address: string;
		CurrencyCode: string;
		RequirePasswordChange: boolean;
		Balance: number;
		BetRequiresComment: boolean;
		PaymentGateway: string;
		TermsAndConditions: string;
		Capabilities: string;
	};

	private userInactiveDateLimit: Date;

	constructor(jwt: any, newToken = true) {
		this.access_token = jwt.access_token;
		this.token_type = jwt.token_type;
		this.expires_in = jwt.expires_in;
		this.refresh_token = jwt.refresh_token;
		this.client_id = jwt.client_id;
		this.userName = jwt.userName;

		this['.issued'] = new Date(jwt['.issued']);
		this['.expires'] = new Date(jwt['.expires']);

		this.setTokenExpiresDate(newToken, jwt);
		this.getPayload();
		this.saveToLocalStorage();
	}

	get inactiveDateLimit(): Date {
		return this.userInactiveDateLimit;
	}

	setInactiveDateLimit() {
		const now = new Date();
		this.userInactiveDateLimit = new Date(now.setSeconds(now.getSeconds() + environment.inactiveTimeLimit));

		this.saveToLocalStorage();
	}

	private setTokenExpiresDate(isNewToken = true, oldToken?: any) {
		if (isNewToken) {
			const now = new Date();
			// this.tokenExpiresDate = new Date(tokenExpires.toISOString());
			this.tokenExpiresDate = new Date(now.setSeconds(now.getSeconds() + this.expires_in));
		} else {
			this.tokenExpiresDate = new Date(oldToken.tokenExpiresDate);
		}
	}

	private getPayload() {
		const accessTokenParts = this.access_token.split('.');
		// As per JWT format, the first str preceding the first dot is header, and the str after is the payload
		this.payload = JSON.parse(Buffer.from(accessTokenParts[1], 'base64').toString());
	}

	private saveToLocalStorage() {
		localStorage.setItem('agentAppToken', JSON.stringify(this));
	}
}
