/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payment-failed.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/router";
import * as i4 from "./payment-failed.component";
var styles_PaymentFailedComponent = [i0.styles];
var RenderType_PaymentFailedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaymentFailedComponent, data: {} });
export { RenderType_PaymentFailedComponent as RenderType_PaymentFailedComponent };
export function View_PaymentFailedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 5, "button", [["class", "default-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 16384, null, 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(8, 1), (_l()(), i1.ɵeld(9, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_2 = _ck(_v, 8, 0, "/"); _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("payments.payment-failed")); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("payments.payment-failed-text")); _ck(_v, 4, 0, currVal_1); var currVal_3 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("general.alerts.ok-btn")); _ck(_v, 10, 0, currVal_3); }); }
export function View_PaymentFailedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-payment-failed", [], null, null, null, View_PaymentFailedComponent_0, RenderType_PaymentFailedComponent)), i1.ɵdid(1, 114688, null, 0, i4.PaymentFailedComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaymentFailedComponentNgFactory = i1.ɵccf("ra-payment-failed", i4.PaymentFailedComponent, View_PaymentFailedComponent_Host_0, {}, {}, []);
export { PaymentFailedComponentNgFactory as PaymentFailedComponentNgFactory };
