export const environment = {
	production: true,
	appInsightsKey: 'cbb0c549-5aee-4425-92be-a919224abc6f',
	env: 'prod',
	defaultTenant: '2',
	authClientID: 'PROD-HE-RA',
	authClientSecret: 'EEF47D9A-DBA9-4D02-B7B0-04F4279A6D20',
	inactiveTimeLimit: 3600,
	helioGameJavaScriptUrl: 'https://cdn.helioengine-ts2.com/launch-game.js'
};
