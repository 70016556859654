import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { of, Subscription } from 'rxjs';
import { map, catchError, delay } from 'rxjs/operators';

import { BaseService, AppConfigService, Dictionary, RequestHeaders, GameConfigService } from 'helio-games-core';

import { environment } from '../../../environments/environment';
import { WindowEventsService } from './window-events.service';
import { AuthToken } from '../models/auth-token.model';
import { AppOverlaysService } from './app-overlays.service';
import { AgentBalanceService } from './agent-balance.service';

@Injectable()
export class AuthService extends BaseService {

	authTokenData: AuthToken = null;

	isUserActive = false;

	private sessionExpiredHandlerSubscription: Subscription;

	constructor(
		protected http: HttpClient,
		private appConfigService: AppConfigService,
		private appOverlaysService: AppOverlaysService,
		private agentBalanceService: AgentBalanceService,
		private gameConfigService: GameConfigService,
		private windowEventsService: WindowEventsService,
		private router: Router
	) {
		super(http, 'oauth2', appConfigService.agentServiceBaseURL);

		const localStorageToken = localStorage.getItem('agentAppToken');
		if (localStorageToken !== null) {
			this.saveToken(JSON.parse(localStorageToken), false);
		}

		this.isUserActiveHandler();
	}

	login(username: string, password: string) {
		const data = {
			username: username,
			password: password,
			grant_type: 'password'
		};

		const requestHeader: RequestHeaders = {
			headersValues: new Dictionary({
				'Authorization': this.getAuthorisationHeaderValue('basic'),
				'Content-Type': 'application/x-www-form-urlencoded'
			}),
			appendToDefaultHeaders: false
		};

		return this.post('/token', data, undefined, requestHeader)
			.pipe(
				map(res => {
					this.saveToken(res);
					return this.authTokenData;
				}),
				catchError(() => {
					return of(null);
				})
			);
	}

	refreshUserToken(autoLogout = true) {
		const data = {
			refresh_token: this.authTokenData.refresh_token,
			grant_type: 'refresh_token',
			client_id: environment.authClientID
		};

		const requestHeader: RequestHeaders = {
			headersValues: new Dictionary({
				'Authorization': this.getAuthorisationHeaderValue('basic'),
				'Content-Type': 'application/x-www-form-urlencoded'
			}),
			appendToDefaultHeaders: false
		};

		return this.post('/token', data, undefined, requestHeader)
			.pipe(
				map(res => {
					this.saveToken(res);
				}),
				catchError(() => {
					if (autoLogout) {
						this.logout();
					}

					return of(null);
				})
			);
	}

	logout() {
		localStorage.removeItem('agentAppToken');
		this.authTokenData = null;

		localStorage.removeItem('agentAppBalance');

		this.appOverlaysService.clearOverlays();

		this.router.navigate(['login']);
	}

	get isLoggedIn(): boolean {
		if (this.authTokenData !== null) {
			const now = new Date();

			return (now < this.authTokenData.tokenExpiresDate);
		}

		return false;
	}

	get isInactivityDateLimitExpired(): boolean {
		if (this.authTokenData !== null) {
			const now = new Date();
			const inactiveDateLimit = this.authTokenData.inactiveDateLimit;

			return (now >= inactiveDateLimit);
		}

		return true;
	}

	get canRefreshToken(): boolean {
		if (this.authTokenData !== null) {
			const now = new Date();
			const refreshTokenExpires = this.authTokenData['.expires'];

			return (now < refreshTokenExpires);
		}

		return false;
	}

	private sessionExpiredHandler() {
		return of(true)
			.pipe(
				delay(this.authTokenData.tokenExpiresDate),
				map(() => {
					if (this.isUserActive) {
						this.refreshUserToken().subscribe(() => {
							this.sessionExpiredHandlerSubscription = this.sessionExpiredHandler().subscribe();
						});
					}
				})
			);
	}

	private saveToken(authResponse: any, newToken = true) {
		this.authTokenData = new AuthToken(authResponse, newToken);

		if (localStorage.getItem('agentAppBalance') !== null) {
			this.agentBalanceService.balance = +localStorage.getItem('agentAppBalance');
		} else {
			this.agentBalanceService.balance = (this.authTokenData.payload.Balance !== undefined && this.authTokenData.payload.Balance !== null) ?
				this.authTokenData.payload.Balance : 0;
		}

		(this.gameConfigService.gameConfig as any) = {
			apiKey: this.authTokenData.payload.APIKey,
			currencyCode: this.authTokenData.payload.CurrencyCode
		};
	}

	private isUserActiveHandler() {
		this.windowEventsService.onFocus.subscribe(() => {
			this.isUserActive = true;

			// if token is valid (i.e. not expired)
			if (this.isLoggedIn) {
				if (this.sessionExpiredHandlerSubscription === undefined) {
					this.sessionExpiredHandlerSubscription = this.sessionExpiredHandler().subscribe();
				}
			} else {
				// check if session can be refreshed
				if (this.canRefreshToken) {
					this.refreshUserToken().subscribe(() => {
						this.unsubscribeSessionExpiredHandler();

						this.sessionExpiredHandlerSubscription = this.sessionExpiredHandler().subscribe();
					});
				} else {
					this.logout();
				}
			}
		});

		this.windowEventsService.onBlur.subscribe(() => {
			this.isUserActive = false;

			this.unsubscribeSessionExpiredHandler();
		});
	}

	private unsubscribeSessionExpiredHandler() {
		if (this.sessionExpiredHandlerSubscription !== undefined) {
			this.sessionExpiredHandlerSubscription.unsubscribe();
		}
	}

	private getAuthorisationHeaderValue(type: 'basic' | 'bearer' = 'bearer'): string {
		const authHeaderToken = btoa(`${environment.authClientID}:${environment.authClientSecret}`);
		return `${type} ${authHeaderToken}`;
	}
}
