/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app-loader.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./app-loader.component";
var styles_AppLoaderComponent = [i0.styles];
var RenderType_AppLoaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppLoaderComponent, data: { "animation": [{ type: 7, name: "fade", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: "250ms" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 6, styles: { opacity: 1 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: "250ms" }], options: null }], options: {} }] } });
export { RenderType_AppLoaderComponent as RenderType_AppLoaderComponent };
export function View_AppLoaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "loader"]], null, null, null, null, null))], null, null); }
export function View_AppLoaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-app-loader", [], null, null, null, View_AppLoaderComponent_0, RenderType_AppLoaderComponent)), i1.ɵdid(1, 114688, null, 0, i2.AppLoaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppLoaderComponentNgFactory = i1.ɵccf("ra-app-loader", i2.AppLoaderComponent, View_AppLoaderComponent_Host_0, {}, {}, []);
export { AppLoaderComponentNgFactory as AppLoaderComponentNgFactory };
