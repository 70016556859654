/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./draw-stats-card.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../game-draw-stats/format-draw-result.pipe";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/router";
import * as i6 from "./draw-stats-card.component";
import * as i7 from "../../app-header/app-lang-select/app-lang.service";
var styles_DrawStatsCardComponent = [i0.styles];
var RenderType_DrawStatsCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DrawStatsCardComponent, data: {} });
export { RenderType_DrawStatsCardComponent as RenderType_DrawStatsCardComponent };
export function View_DrawStatsCardComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), i1.ɵpid(0, i3.FormatDrawResultPipe, []), (_l()(), i1.ɵeld(2, 0, null, null, 13, "div", [["class", "card-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "game-logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 10, "div", [["class", "info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵppd(10, 4), i1.ɵpod(11, { drawDate: 0 }), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "draw-result"]], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, [" ", " "])), i1.ɵppd(15, 1), (_l()(), i1.ɵeld(16, 0, null, null, 6, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 5, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 18).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(18, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i2.LocationStrategy], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i1.ɵpod(19, { name: 0 }), (_l()(), i1.ɵeld(20, 0, null, null, 2, "button", [["class", "default-btn"]], null, null, null, null, null)), (_l()(), i1.ɵted(21, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_6 = _ck(_v, 19, 0, _co.lastDrawData.name); var currVal_7 = i1.ɵinlineInterpolate(1, "/draw-stats/", _co.lastDrawData.gameGroupCode, ""); _ck(_v, 18, 0, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "logo ", _co.lastDrawData.gameGroupCode, ""); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.lastDrawData.name; _ck(_v, 7, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 12).transform("draw-stats.card.last-draw", _ck(_v, 11, 0, i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v, 0), _co.drawDate, "short", undefined, _co.localeID))))); _ck(_v, 9, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 14, 0, _ck(_v, 15, 0, i1.ɵnov(_v, 1), _co.lastDrawData.lastDraw.results)); _ck(_v, 14, 0, currVal_3); var currVal_4 = i1.ɵnov(_v, 18).target; var currVal_5 = i1.ɵnov(_v, 18).href; _ck(_v, 17, 0, currVal_4, currVal_5); var currVal_8 = i1.ɵunv(_v, 21, 0, i1.ɵnov(_v, 22).transform("draw-stats.card.view-more-btn")); _ck(_v, 21, 0, currVal_8); }); }
export function View_DrawStatsCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-draw-stats-card", [], null, null, null, View_DrawStatsCardComponent_0, RenderType_DrawStatsCardComponent)), i1.ɵdid(1, 114688, null, 0, i6.DrawStatsCardComponent, [i7.AppLangService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DrawStatsCardComponentNgFactory = i1.ɵccf("ra-draw-stats-card", i6.DrawStatsCardComponent, View_DrawStatsCardComponent_Host_0, { lastDrawData: "lastDrawData" }, {}, []);
export { DrawStatsCardComponentNgFactory as DrawStatsCardComponentNgFactory };
