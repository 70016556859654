/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./install-app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./install-app.component";
import * as i4 from "../shared/services/pwa.service";
var styles_InstallAppComponent = [i0.styles];
var RenderType_InstallAppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InstallAppComponent, data: {} });
export { RenderType_InstallAppComponent as RenderType_InstallAppComponent };
export function View_InstallAppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 7, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "i", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "button", [["class", "default-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.installApp() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(14, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("install-app.title")); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("install-app.message")); _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("install-app.note")); _ck(_v, 9, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("install-app.install-btn")); _ck(_v, 14, 0, currVal_3); }); }
export function View_InstallAppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-install-app", [], null, null, null, View_InstallAppComponent_0, RenderType_InstallAppComponent)), i1.ɵdid(1, 114688, null, 0, i3.InstallAppComponent, [i4.PwaService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InstallAppComponentNgFactory = i1.ɵccf("ra-install-app", i3.InstallAppComponent, View_InstallAppComponent_Host_0, {}, {}, []);
export { InstallAppComponentNgFactory as InstallAppComponentNgFactory };
