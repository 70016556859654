import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { appInitLoader } from './app-init.loaders';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr);
const ɵ0 = appInitLoader;
export class AppModule {
}
// required for AOT compilation
export function HttpLoaderFactory(http) {
    return new TranslateHttpLoader(http, './assets/i18n/');
}
export { ɵ0 };
