import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

import { TranslateService } from '@ngx-translate/core';

import { AppOverlaysService } from './app-overlays.service';
import { AlertComponent } from '../../alert';

@Injectable()
export class PwaService {
	promptEvent: any;

	constructor(
		private swUpdate: SwUpdate,
		private overlaysService: AppOverlaysService,
		private translateService: TranslateService
	) {
		swUpdate.available.subscribe(e => {
			this.overlaysService.loadOverlay(AlertComponent, (alertComponent: AlertComponent) => {
				alertComponent.contentText = this.translateService.instant('general.app-update.message');
				alertComponent.closeBtnText = this.translateService.instant('general.app-update.update-btn');

				alertComponent.closeBtnClick.subscribe(() => {
					window.location.reload();
				});
			});
		});

		swUpdate.activated.subscribe(() => {
			this.overlaysService.loadOverlay(AlertComponent, (alertComponent: AlertComponent) => {
				alertComponent.contentText = this.translateService.instant('general.app-update.updated-success');
			});
		});
	}
}
