/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./coupon-picker.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../node_modules/helio-games-core/helio-games-core.ngfactory";
import * as i4 from "helio-games-core";
import * as i5 from "@angular/common";
import * as i6 from "./coupon-picker.component";
import * as i7 from "../buy-ticket.service";
import * as i8 from "@angular/router";
import * as i9 from "../../shared/services/capabilities.service";
var styles_CouponPickerComponent = [i0.styles];
var RenderType_CouponPickerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CouponPickerComponent, data: {} });
export { RenderType_CouponPickerComponent as RenderType_CouponPickerComponent };
function View_CouponPickerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpod(2, { "numbersTot": 0, "maxNumbersTot": 1 }), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 3).transform((_co.allowComboBets ? "buy-ticket.choose-numbers-one-board-combo" : "buy-ticket.choose-numbers-one-board"), _ck(_v, 2, 0, _co.boardNumsToSelect[0], _co.maxBoardNumsToSelect[0]))); _ck(_v, 1, 0, currVal_0); }); }
function View_CouponPickerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpod(2, { "boardOneNumbersTot": 0, "boardTwoNumbersTot": 1 }), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 3).transform((_co.allowComboBets ? "buy-ticket.choose-numbers-two-boards-combo" : "buy-ticket.choose-numbers-two-boards"), _ck(_v, 2, 0, _co.boardNumsToSelect[0], _co.boardNumsToSelect[1]))); _ck(_v, 1, 0, currVal_0); }); }
function View_CouponPickerComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "number"]], [[2, "is-picked", null], [2, "dark", null], [2, "combo-bet", null]], null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.isPicked; var currVal_1 = ((_v.parent.context.index % 2) !== 0); var currVal_2 = _v.context.$implicit.isComboBetPick; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _v.context.$implicit.number; _ck(_v, 1, 0, currVal_3); }); }
function View_CouponPickerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "hg-board", [["boardType", "number"]], null, [[null, "itemPick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("itemPick" === en)) {
        var pd_0 = (_co.pickNumber() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ɵbo_0, i3.RenderType_ɵbo)), i1.ɵdid(1, 114688, [[4, 4], [3, 4], ["board", 4]], 1, i4.ɵbo, [i4.ɵd], { boardType: [0, "boardType"], boardMinNumber: [1, "boardMinNumber"], boardMaxNumber: [2, "boardMaxNumber"], boardData: [3, "boardData"], allowCombos: [4, "allowCombos"] }, { itemPick: "itemPick" }), i1.ɵqud(603979776, 5, { itemTmpl: 0 }), (_l()(), i1.ɵand(0, [[5, 2]], null, 0, null, View_CouponPickerComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "number"; var currVal_1 = _v.context.$implicit.minNumber; var currVal_2 = _v.context.$implicit.maxNumber; var currVal_3 = _v.context.$implicit; var currVal_4 = _co.allowComboBets; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_CouponPickerComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpod(2, { "drawsAmount": 0 }), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 3).transform("draw-selector.values.draws", _ck(_v, 2, 0, _co.consecutiveDraws[0].numOfDraws))); _ck(_v, 1, 0, currVal_0); }); }
function View_CouponPickerComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpod(2, { "drawsAmount": 0 }), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 3).transform("draw-selector.values.draws", _ck(_v, 2, 0, _v.context.$implicit.numOfDraws))); _ck(_v, 1, 0, currVal_0); }); }
function View_CouponPickerComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedGames[0].tier; _ck(_v, 1, 0, currVal_0); }); }
function View_CouponPickerComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.tier; _ck(_v, 1, 0, currVal_0); }); }
function View_CouponPickerComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "hg-dropdown", [], null, [[null, "listSelectionChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("listSelectionChange" === en)) {
        var pd_0 = (_co.selectedGameChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ɵbn_0, i3.RenderType_ɵbn)), i1.ɵdid(1, 4833280, null, 2, i4.ɵbn, [i1.ChangeDetectorRef], { list: [0, "list"], listSelection: [1, "listSelection"] }, { listSelectionChange: "listSelectionChange" }), i1.ɵqud(603979776, 8, { selectedItemTemplate: 0 }), i1.ɵqud(603979776, 9, { itemsTemplate: 0 }), (_l()(), i1.ɵand(0, [[8, 2], ["selectedItemTemplate", 2]], null, 0, null, View_CouponPickerComponent_8)), (_l()(), i1.ɵand(0, [[9, 2], ["itemsTemplate", 2]], null, 0, null, View_CouponPickerComponent_9))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.games; var currVal_1 = _co.selectedGames; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_CouponPickerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { scrollToElement: 0 }), i1.ɵqud(671088640, 2, { couponPicker: 0 }), i1.ɵqud(671088640, 3, { boards: 1 }), (_l()(), i1.ɵeld(3, 0, null, null, 3, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpod(5, { gameName: 0 }), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 0, "button", [["class", "close-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeCouponPicker() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CouponPickerComponent_1)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CouponPickerComponent_2)), i1.ɵdid(12, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 4, "hg-coupon-picker", [], null, null, null, i3.View_ɵbr_0, i3.RenderType_ɵbr)), i1.ɵdid(14, 4243456, [[2, 4], ["couponPicker", 4]], 1, i4.ɵbr, [i4.ɵc], null, null), i1.ɵqud(603979776, 4, { boards: 1 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_CouponPickerComponent_3)), i1.ɵdid(17, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 17, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 5, "hg-dropdown", [], null, [[null, "listSelectionChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("listSelectionChange" === en)) {
        var pd_0 = (_co.consecutiveDrawsChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ɵbn_0, i3.RenderType_ɵbn)), i1.ɵdid(20, 4833280, null, 2, i4.ɵbn, [i1.ChangeDetectorRef], { list: [0, "list"], listSelection: [1, "listSelection"] }, { listSelectionChange: "listSelectionChange" }), i1.ɵqud(603979776, 6, { selectedItemTemplate: 0 }), i1.ɵqud(603979776, 7, { itemsTemplate: 0 }), (_l()(), i1.ɵand(0, [[6, 2], ["selectedItemTemplate", 2]], null, 0, null, View_CouponPickerComponent_5)), (_l()(), i1.ɵand(0, [[7, 2], ["itemsTemplate", 2]], null, 0, null, View_CouponPickerComponent_6)), (_l()(), i1.ɵeld(25, 0, null, null, 2, "button", [["class", "default-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.randomPick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(26, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(28, 0, null, null, 2, "button", [["class", "default-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clearSelection() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(29, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CouponPickerComponent_7)), i1.ɵdid(32, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(33, 0, [[1, 0], ["scrollToElement", 1]], null, 2, "button", [["class", "default-btn"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addSelection() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(34, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.gameBoards.length === 1); _ck(_v, 10, 0, currVal_1); var currVal_2 = (_co.gameBoards.length > 1); _ck(_v, 12, 0, currVal_2); var currVal_3 = _co.gameBoards; _ck(_v, 17, 0, currVal_3); var currVal_4 = _co.consecutiveDrawsItems; var currVal_5 = _co.consecutiveDraws; _ck(_v, 20, 0, currVal_4, currVal_5); var currVal_8 = _co.allowMultiBets; _ck(_v, 32, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 6).transform("buy-ticket.generate-ticket", _ck(_v, 5, 0, _co.currentGameGroup.name))); _ck(_v, 4, 0, currVal_0); var currVal_6 = i1.ɵunv(_v, 26, 0, i1.ɵnov(_v, 27).transform("coupon-picker.random-btn")); _ck(_v, 26, 0, currVal_6); var currVal_7 = i1.ɵunv(_v, 29, 0, i1.ɵnov(_v, 30).transform("coupon-picker.clear-selection-btn")); _ck(_v, 29, 0, currVal_7); var currVal_9 = !_co.minItemsSelected; _ck(_v, 33, 0, currVal_9); var currVal_10 = i1.ɵunv(_v, 34, 0, i1.ɵnov(_v, 35).transform("coupon-picker.add-selection-btn")); _ck(_v, 34, 0, currVal_10); }); }
export function View_CouponPickerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-coupon-picker", [], null, null, null, View_CouponPickerComponent_0, RenderType_CouponPickerComponent)), i1.ɵdid(1, 4308992, null, 0, i6.CouponPickerComponent, [i7.BuyTicketService, i4.GameService, i8.Router, i9.CapabilitiesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CouponPickerComponentNgFactory = i1.ɵccf("ra-coupon-picker", i6.CouponPickerComponent, View_CouponPickerComponent_Host_0, {}, {}, []);
export { CouponPickerComponentNgFactory as CouponPickerComponentNgFactory };
