/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./no-content.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "./no-content.component";
var styles_NoContentComponent = [i0.styles];
var RenderType_NoContentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NoContentComponent, data: {} });
export { RenderType_NoContentComponent as RenderType_NoContentComponent };
export function View_NoContentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 8, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 3, "a", [["routerLink", "/home"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(10, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_4 = "/home"; _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("general.errors.page-not-found.title")); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("general.errors.page-not-found.message")); _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 9).target; var currVal_3 = i1.ɵnov(_v, 9).href; _ck(_v, 8, 0, currVal_2, currVal_3); var currVal_5 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("general.errors.page-not-found.go-back-home")); _ck(_v, 10, 0, currVal_5); }); }
export function View_NoContentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ra-no-content", [], null, null, null, View_NoContentComponent_0, RenderType_NoContentComponent)), i1.ɵdid(1, 114688, null, 0, i5.NoContentComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NoContentComponentNgFactory = i1.ɵccf("ra-no-content", i5.NoContentComponent, View_NoContentComponent_Host_0, {}, {}, []);
export { NoContentComponentNgFactory as NoContentComponentNgFactory };
