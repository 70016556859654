import { Component, OnInit, AfterContentInit, HostBinding } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';

import {
	atLeastOneLowerCaseLetter, atLeastOneUpperCaseLetter, atLeastOneNumber,
	atLeastOneSymbol, containsUsername, sameString
} from './validators';
import { AuthService, AppOverlaysService } from '../shared/services';
import { ChangePasswordService } from './change-password.service';
import { AlertComponent } from '../alert';
import { appOverlaysAnimations } from '../app-overlays.animations';

@Component({
	selector: 'ra-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.scss'],
	animations: [appOverlaysAnimations]
})
export class ChangePasswordComponent implements OnInit, AfterContentInit {

	@HostBinding('@fade') fade = true;

	changePasswordForm: FormGroup;
	isLoading = false;

	username: string;

	errors: string[] = [];

	constructor(
		private authService: AuthService,
		private formBuilder: FormBuilder,
		private changePasswordService: ChangePasswordService,
		private appOverlaysService: AppOverlaysService,
		private translateService: TranslateService
	) { }

	ngOnInit() {
		this.username = this.authService.authTokenData.userName;
	}

	ngAfterContentInit() {
		this.initForm();
	}

	changePassword() {
		if (!this.isLoading) {
			this.isLoading = true;
			this.changePasswordService.changePassword(
				this.changePasswordForm.get('oldPassword').value,
				this.changePasswordForm.get('newPassword').value
			).subscribe(() => {
				this.isLoading = false;
				this.refreshToken();
			}, error => {
				this.isLoading = false;
				this.appOverlaysService.loadOverlay(AlertComponent, (alertComponent: AlertComponent) => {
					alertComponent.type = 'error';
					alertComponent.contentText = this.translateService.instant('change-password.pass-change-error');
				});
			});
		}
	}

	private refreshToken() {
		this.authService.refreshUserToken(false).subscribe(() => {
			this.appOverlaysService.removeOverlay();
			this.appOverlaysService.loadOverlay(AlertComponent, (alertComponent: AlertComponent) => {
				alertComponent.type = 'info';
				alertComponent.contentText = this.translateService.instant('change-password.pass-change-success');
			});
		}, error => {
			this.appOverlaysService.removeOverlay();
			this.appOverlaysService.loadOverlay(AlertComponent, (alertComponent: AlertComponent) => {
				alertComponent.type = 'info';
				alertComponent.contentText = this.translateService.instant('change-password.pass-change-success-logout');

				alertComponent.closeBtnClick.subscribe(() => {
					this.authService.logout();
				});
			});
		});
	}

	private initForm() {
		this.changePasswordForm = this.formBuilder.group({
			oldPassword: new FormControl('', Validators.required),
			newPassword: new FormControl(''),
			newPasswordConfirm: new FormControl('')
		});

		this.changePasswordForm.get('newPassword').setValidators([
			Validators.required,
			Validators.minLength(8),
			Validators.maxLength(128),
			atLeastOneLowerCaseLetter(),
			atLeastOneUpperCaseLetter(),
			atLeastOneNumber(),
			atLeastOneSymbol(),
			(control: AbstractControl) => containsUsername(this.username)(control),
			(control: AbstractControl) => sameString(this.changePasswordForm.get('oldPassword').value)(control)
		]);

		this.changePasswordForm.get('newPasswordConfirm').setValidators([
			Validators.required,
			(control: AbstractControl) => sameString(this.changePasswordForm.get('newPassword').value, true)(control)
		]);

		// this.changePasswordForm.get('newPassword').valueChanges
		// 	.subscribe(() => {
		// 		const validatorErrors = this.changePasswordForm.get('newPassword').errors;

		// 		if (validatorErrors !== undefined && validatorErrors !== null) {
		// 			const objectKeys = Object.keys(validatorErrors);
		// 			for (const tempValidator of objectKeys) {
		// 				const error = ValidationService.getValidatorErrorMessage(tempValidator, validatorErrors[tempValidator]);
		// 				if (error !== undefined) {
		// 					this.errors = error;
		// 				}
		// 			}
		// 		}
		// 	});
	}

}
